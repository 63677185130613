export const missionCheckStatus = 'CHECK_STATUS_MISSION'

export const euCountries = [
  'AT', // Австрия
  'BE', // Бельгия
  'BG', // Болгария
  'HR', // Хорватия
  'CY', // Кипр
  'CZ', // Чехия
  'DK', // Дания
  'EE', // Эстония
  'FI', // Финляндия
  'FR', // Франция
  'DE', // Германия
  'GR', // Греция
  'HU', // Венгрия
  'IE', // Ирландия
  'IT', // Италия
  'LV', // Латвия
  'LT', // Литва
  'LU', // Люксембург
  'MT', // Мальта
  'NL', // Нидерланды
  'PL', // Польша
  'PT', // Португалия
  'RO', // Румыния
  'SK', // Словакия
  'SI', // Словения
  'ES', // Испания
  'SE'  // Швеция
];
